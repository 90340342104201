import Vue from "vue";

import {createApp, createRouter} from '@waln/starter';
import {transformRoutes, collectScreenFlow} from "@twor/src/utils";
import {mergeStores, createPersistRef} from '@waln/utils/StoreUtils';
import {createVuetify} from '@waln/plugins/PluginVuetify';

import ComponentConstructor from "@twor/src/components/ComponentConstructor.vue";
import ComponentConstructorContent from "@twor/src/components/ComponentConstructorContent.vue";

import cc from './assets/CollectionComponents.js';
import LogoPic from "@waln/apps/lackhite/components/LogoPic.vue";

import AppStore from '@waln/utils/AppStore';
import AppApi from "@waln/utils/AppApi";
import StyleSet from "./styles/StyleSet";
import mainStore from './mainStore';

const localStore = {
  meta: {
    homePath: '/home',

    // ???
    barBottomGrow: true,
    barBottomSqueeze: false,
  },

  main: {
    key: createPersistRef('key', process.env.VUE_APP_KEY),
    ...mainStore,
  },

  settings: {
    isThemeDark: createPersistRef('isThemeDark', false),
  },

  app: {
    links: [
      {name: 'Главная', to: '/home', icon: 'fa-solid fa-house'},
      {name: 'Заказы', to: '/orders', icon: 'fa-solid fa-book'},
      {name: 'Профиль', to: '/profile', icon: 'fa-solid fa-user'},
    ],
  },
};

const store = mergeStores(AppStore, localStore);

const api = {
  app: new AppApi(),
  main: {
    init() {},
  },
};

// eslint-disable-next-line
const vuetify = createVuetify({
  styleSet: StyleSet,
  isDark: store.settings.isThemeDark.value,
});

// function makeLayout(layout) {
//   return () => import(`@threestyle/src/components/${layout}.vue`)
// }

const pages = [
  {
    name: 'home',
    blocks: [
      {
        c: cc.app.HomeSearch,
      },

      {
        c: cc.app.RestoCards,
        props: {items: store.main.restaurants },
        listeners: {click: index => {window.__app.$router.push(`/home/rest?restId=${index}`);}},
      },

      {
        c: cc.div,
        style: {
          height: '48px',
        },
      },
    ],
  },

  {
    name: 'rest',
    path: '/home/rest',
    meta: {nestingLevel: 1,},
    blocks: [
      {
        c: cc.app.BarTool,
        class: 'py-2',
        props: {header: 'Рест'},
        listeners: {back() {window.__app.$router.back();}},
      },

      {
        c: cc.app.RestoDetails,
        props: {restaurants: store.main.restaurants, foods: store.main.foods, foodTypes: store.main.foodTypes },
      },
    ],
  },

  {
    name: 'rest',
    path: '/home/rest/food',
    meta: {
      nestingLevel: 2,
    },
    blocks: [
      {
        c: cc.app.BarTool,
        class: 'py-2',
        props: {header: 'Еда'},
        listeners: {
          back() {
            window.__app.$router.back();
          }
        },
      },

      {
        c: cc.app.FoodDetails,
        props: {restaurants: store.main.restaurants, foods: store.main.foods, foodTypes: store.main.foodTypes },
      },

      {
        c: cc.div,
        class: 'd-flex justify-center',
        slot: {
          c: cc.btn,
          slot: 'Добавить',
          props: {large: true, color: 'primary', block: true},
          listeners: {click: () => {window.__app.$api.app.snackDev();}},
        },
      },
    ],
  },

  {
    name: 'orders',
    blocks: [
      {
        c: cc.app.OrderCards,
        listeners: {click: index => {window.__app.$router.push(`/orders/item?index=${index}`);}},
      },

      {
        c: cc.div,
        style: {
          height: '48px',
        },
      },
    ],
  },

  {
    name: 'order-item',
    path: '/orders/item',
    meta: {
      nestingLevel: 2,
    },
    blocks: [
      {
        c: cc.app.BarTool,
        class: 'py-2',
        props: {header: 'Заказ'},
        listeners: {back() {window.__app.$router.back();}},
      },

      {
        c: cc.app.OrderDetails,
        // props: {items: store.main.orders,},
      },

      // {
      //   c: cc.div,
      //   class: 'd-flex justify-center',
      //   slot: {
      //     c: cc.btn,
      //     slot: 'Забронировать',
      //     props: {large: true, color: 'primary', block: true},
      //     listeners: {click: () => {window.__app.$api.app.snackDev();}},
      //   },
      // },
    ],
  },

  {
    name: 'profile',
    blocks: [
      {
        c: cc.div,
        class: 'd-flex justify-center mb-0',
        blocks: [
          {
            c: cc.v.VAvatar,
            slot: {
              c: cc.v.VImg,
              props: {
                src: require('./assets/user.png'),
                size: 96,
              },
            },
            props: {size: 96,}
          },
        ],
      },
      {
        t: '<h3>Петр Демьянов</h3>',
        class: 'text-center',
      },
      {
        c: cc.div,
        blocks: [
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Профиль',
              description: 'Отредактируйте данные вашего профиля',
              icon: 'fa-solid fa-user',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Методы оплаты',
              description: 'Добавить, изменить и удалить методы оплаты',
              icon: 'fa-solid fa-credit-card',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Настойки',
              description: 'Изменить настройки приложения и аккаунта',
              icon: 'fa-solid fa-gears',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Техническая поддержка',
              description: 'Обратитесь за помощью или сообщите о найденных ошибках',
              icon: 'fa-solid fa-question',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
        ],
      },

      {
        c: cc.div,
        style: {
          height: '48px',
        },
      },
    ],
  },
];

const transformed = await transformRoutes(pages, StyleSet);

// for debug
window.__waln = {
  // client: appInstance,
  // StyleSet,
  screenFlow: collectScreenFlow(pages),
};

const router = createRouter([
  ...transformed,
  {path: '/', redirect: '/home'},
  {path: '/*', redirect: '/home'},
]);

/**
 * For correct recursive import
 *
 * did you register the component correctly? For recursive components, make sure to provide the "name" option
 * https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
 * https://stackoverflow.com/a/58875919
 */
Vue.component('ComponentConstructorContent', ComponentConstructorContent);
Vue.component('ComponentConstructor', ComponentConstructor);

createApp({
  router,
  store,
  api,
  vuetify,
  LogoPic,
});

// after creating and mounting app

document.documentElement.style.setProperty('--font-family', 'Verdana');
document.documentElement.style.setProperty('--font-mono', 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace');

import ('./styles/theme.sass')
