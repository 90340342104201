export default {
	app: {
		BarTool: {
			_twor: true,
			source: 'app',
			name: 'BarTool',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/BarTool.vue')).default,
		},
		FoodCard: {
			_twor: true,
			source: 'app',
			name: 'FoodCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/FoodCard.vue')).default,
		},
		FoodCardVertical: {
			_twor: true,
			source: 'app',
			name: 'FoodCardVertical',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/FoodCardVertical.vue')).default,
		},
		FoodDetails: {
			_twor: true,
			source: 'app',
			name: 'FoodDetails',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/FoodDetails.vue')).default,
		},
		HomeSearch: {
			_twor: true,
			source: 'app',
			name: 'HomeSearch',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/HomeSearch.vue')).default,
		},
		OrderCards: {
			_twor: true,
			source: 'app',
			name: 'OrderCards',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/OrderCards.vue')).default,
		},
		OrderDetails: {
			_twor: true,
			source: 'app',
			name: 'OrderDetails',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/OrderDetails.vue')).default,
		},
		RestoCards: {
			_twor: true,
			source: 'app',
			name: 'RestoCards',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/RestoCards.vue')).default,
		},
		RestoDetails: {
			_twor: true,
			source: 'app',
			name: 'RestoDetails',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/components/RestoDetails.vue')).default,
		},
	},
	waln: {
		BlockEmpty: {
			_twor: true,
			source: 'waln',
			name: 'BlockEmpty',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/BlockEmpty.vue')).default,
		},
		ButtonDefault: {
			_twor: true,
			source: 'waln',
			name: 'ButtonDefault',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/ButtonDefault.vue')).default,
		},
		ButtonInput: {
			_twor: true,
			source: 'waln',
			name: 'ButtonInput',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/ButtonInput.vue')).default,
		},
		JsonViewerText: {
			_twor: true,
			source: 'waln',
			name: 'JsonViewerText',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/JsonViewerText.vue')).default,
		},
		LogoPic: {
			_twor: true,
			source: 'waln',
			name: 'LogoPic',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/LogoPic.vue')).default,
		},
		LogoText: {
			_twor: true,
			source: 'waln',
			name: 'LogoText',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/LogoText.vue')).default,
		},
		RawView: {
			_twor: true,
			source: 'waln',
			name: 'RawView',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/RawView.vue')).default,
		},
		TextView: {
			_twor: true,
			source: 'waln',
			name: 'TextView',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/TextView.vue')).default,
		},
		TextViewer: {
			_twor: true,
			source: 'waln',
			name: 'TextViewer',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/TextViewer.vue')).default,
		},
		base: {
			BarApp: {
				_twor: true,
				source: 'waln',
				name: 'BarApp',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/BarApp.vue')).default,
			},
			BarBottom: {
				_twor: true,
				source: 'waln',
				name: 'BarBottom',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/BarBottom.vue')).default,
			},
			BarBottomSimple: {
				_twor: true,
				source: 'waln',
				name: 'BarBottomSimple',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/BarBottomSimple.vue')).default,
			},
			BarSnack: {
				_twor: true,
				source: 'waln',
				name: 'BarSnack',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/BarSnack.vue')).default,
			},
			BarTool: {
				_twor: true,
				source: 'waln',
				name: 'BarTool',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/BarTool.vue')).default,
			},
			ButtonAll: {
				_twor: true,
				source: 'waln',
				name: 'ButtonAll',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ButtonAll.vue')).default,
			},
			CardButton: {
				_twor: true,
				source: 'waln',
				name: 'CardButton',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/CardButton.vue')).default,
			},
			CardLink: {
				_twor: true,
				source: 'waln',
				name: 'CardLink',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/CardLink.vue')).default,
			},
			Charts: {
				ChartLine: {
					_twor: true,
					source: 'waln',
					name: 'ChartLine',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Charts/ChartLine.vue')).default,
				},
			},
			CollectorVirtual: {
				_twor: true,
				source: 'waln',
				name: 'CollectorVirtual',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/CollectorVirtual.vue')).default,
			},
			DialogConfirm: {
				_twor: true,
				source: 'waln',
				name: 'DialogConfirm',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/DialogConfirm.vue')).default,
			},
			FilterChips: {
				_twor: true,
				source: 'waln',
				name: 'FilterChips',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/FilterChips.vue')).default,
			},
			Forms: {
				FormPoll: {
					_twor: true,
					source: 'waln',
					name: 'FormPoll',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Forms/FormPoll.vue')).default,
				},
			},
			Inputs: {
				InputBase: {
					_twor: true,
					source: 'waln',
					name: 'InputBase',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Inputs/InputBase.vue')).default,
				},
				InputButtonGroup: {
					_twor: true,
					source: 'waln',
					name: 'InputButtonGroup',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Inputs/InputButtonGroup.vue')).default,
				},
				InputPhone: {
					_twor: true,
					source: 'waln',
					name: 'InputPhone',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Inputs/InputPhone.vue')).default,
				},
			},
			PageBlank: {
				_twor: true,
				source: 'waln',
				name: 'PageBlank',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/PageBlank.vue')).default,
			},
			Preview: {
				PreviewFrame: {
					_twor: true,
					source: 'waln',
					name: 'PreviewFrame',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Preview/PreviewFrame.vue')).default,
				},
				PreviewLayoutFrame: {
					_twor: true,
					source: 'waln',
					name: 'PreviewLayoutFrame',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Preview/PreviewLayoutFrame.vue')).default,
				},
				PreviewLayoutFrameBrowser: {
					_twor: true,
					source: 'waln',
					name: 'PreviewLayoutFrameBrowser',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Preview/PreviewLayoutFrameBrowser.vue')).default,
				},
				PreviewLayoutFrameMobile: {
					_twor: true,
					source: 'waln',
					name: 'PreviewLayoutFrameMobile',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/Preview/PreviewLayoutFrameMobile.vue')).default,
				},
			},
			TabsSwipe: {
				_twor: true,
				source: 'waln',
				name: 'TabsSwipe',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/TabsSwipe.vue')).default,
			},
			ViewDialog: {
				_twor: true,
				source: 'waln',
				name: 'ViewDialog',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewDialog.vue')).default,
			},
			ViewError: {
				_twor: true,
				source: 'waln',
				name: 'ViewError',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewError.vue')).default,
			},
			ViewerTools: {
				MediaViewer: {
					_twor: true,
					source: 'waln',
					name: 'MediaViewer',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewerTools/MediaViewer.vue')).default,
				},
				StoriesProgress: {
					_twor: true,
					source: 'waln',
					name: 'StoriesProgress',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewerTools/StoriesProgress.vue')).default,
				},
				ViewerCard: {
					_twor: true,
					source: 'waln',
					name: 'ViewerCard',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewerTools/ViewerCard.vue')).default,
				},
				ViewerModal: {
					_twor: true,
					source: 'waln',
					name: 'ViewerModal',
					import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/base/ViewerTools/ViewerModal.vue')).default,
				},
			},
		},
		chat: {
			ChatConversation: {
				_twor: true,
				source: 'waln',
				name: 'ChatConversation',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatConversation.vue')).default,
			},
			ChatConversators: {
				_twor: true,
				source: 'waln',
				name: 'ChatConversators',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatConversators.vue')).default,
			},
			ChatMessage: {
				_twor: true,
				source: 'waln',
				name: 'ChatMessage',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatMessage.vue')).default,
			},
			ChatMessageBig: {
				_twor: true,
				source: 'waln',
				name: 'ChatMessageBig',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatMessageBig.vue')).default,
			},
			ChatRoom: {
				_twor: true,
				source: 'waln',
				name: 'ChatRoom',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatRoom.vue')).default,
			},
			ChatViewer: {
				_twor: true,
				source: 'waln',
				name: 'ChatViewer',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/ChatViewer.vue')).default,
			},
			utils: {
				_twor: true,
				source: 'waln',
				name: 'utils',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/chat/utils.js')).default,
			},
		},
		icons: {
			IconClose: {
				_twor: true,
				source: 'waln',
				name: 'IconClose',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconClose.vue')).default,
			},
			IconDot: {
				_twor: true,
				source: 'waln',
				name: 'IconDot',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconDot.vue')).default,
			},
			IconEgg: {
				_twor: true,
				source: 'waln',
				name: 'IconEgg',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconEgg.vue')).default,
			},
			IconTelegram: {
				_twor: true,
				source: 'waln',
				name: 'IconTelegram',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconTelegram.vue')).default,
			},
			IconVegan: {
				_twor: true,
				source: 'waln',
				name: 'IconVegan',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconVegan.vue')).default,
			},
			IconWhatsApp: {
				_twor: true,
				source: 'waln',
				name: 'IconWhatsApp',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/icons/IconWhatsApp.vue')).default,
			},
		},
		layouts: {
			CollectorDefault: {
				_twor: true,
				source: 'waln',
				name: 'CollectorDefault',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/CollectorDefault.vue')).default,
			},
			CollectorRepeater: {
				_twor: true,
				source: 'waln',
				name: 'CollectorRepeater',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/CollectorRepeater.vue')).default,
			},
			CollectorVirtual: {
				_twor: true,
				source: 'waln',
				name: 'CollectorVirtual',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/CollectorVirtual.vue')).default,
			},
			LayoutCardSimple: {
				_twor: true,
				source: 'waln',
				name: 'LayoutCardSimple',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutCardSimple.vue')).default,
			},
			LayoutCards: {
				_twor: true,
				source: 'waln',
				name: 'LayoutCards',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutCards.vue')).default,
			},
			LayoutFull: {
				_twor: true,
				source: 'waln',
				name: 'LayoutFull',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutFull.vue')).default,
			},
			LayoutHorizontal: {
				_twor: true,
				source: 'waln',
				name: 'LayoutHorizontal',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutHorizontal.vue')).default,
			},
			LayoutHorizontalSnap: {
				_twor: true,
				source: 'waln',
				name: 'LayoutHorizontalSnap',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutHorizontalSnap.vue')).default,
			},
			LayoutHorizontalSwipe: {
				_twor: true,
				source: 'waln',
				name: 'LayoutHorizontalSwipe',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutHorizontalSwipe.vue')).default,
			},
			LayoutOneColumn: {
				_twor: true,
				source: 'waln',
				name: 'LayoutOneColumn',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutOneColumn.vue')).default,
			},
			LayoutPage: {
				_twor: true,
				source: 'waln',
				name: 'LayoutPage',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/LayoutPage.vue')).default,
			},
			WireframeCollection: {
				_twor: true,
				source: 'waln',
				name: 'WireframeCollection',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/WireframeCollection.vue')).default,
			},
			WireframeNested: {
				_twor: true,
				source: 'waln',
				name: 'WireframeNested',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/WireframeNested.vue')).default,
			},
			WireframeOneColumn: {
				_twor: true,
				source: 'waln',
				name: 'WireframeOneColumn',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/layouts/WireframeOneColumn.vue')).default,
			},
		},
		microblog: {
			MicroBlogFeed: {
				_twor: true,
				source: 'waln',
				name: 'MicroBlogFeed',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/microblog/MicroBlogFeed.vue')).default,
			},
		},
		profile: {
			ProfileAvatar: {
				_twor: true,
				source: 'waln',
				name: 'ProfileAvatar',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/profile/ProfileAvatar.vue')).default,
			},
		},
		samples: {
			CardListItem: {
				_twor: true,
				source: 'waln',
				name: 'CardListItem',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/CardListItem.vue')).default,
			},
			CardSample: {
				_twor: true,
				source: 'waln',
				name: 'CardSample',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/CardSample.vue')).default,
			},
			LayoutTreeColumns: {
				_twor: true,
				source: 'waln',
				name: 'LayoutTreeColumns',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/LayoutTreeColumns.vue')).default,
			},
			SampleDynamicComponent: {
				_twor: true,
				source: 'waln',
				name: 'SampleDynamicComponent',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleDynamicComponent.vue')).default,
			},
			SampleIcons: {
				_twor: true,
				source: 'waln',
				name: 'SampleIcons',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleIcons.vue')).default,
			},
			SamplePage: {
				_twor: true,
				source: 'waln',
				name: 'SamplePage',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SamplePage.vue')).default,
			},
			SampleSlider: {
				_twor: true,
				source: 'waln',
				name: 'SampleSlider',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleSlider.vue')).default,
			},
			SampleTimeline: {
				_twor: true,
				source: 'waln',
				name: 'SampleTimeline',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleTimeline.vue')).default,
			},
			SampleTimelineVuetify: {
				_twor: true,
				source: 'waln',
				name: 'SampleTimelineVuetify',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleTimelineVuetify.vue')).default,
			},
			SampleVuetifyDynamic: {
				_twor: true,
				source: 'waln',
				name: 'SampleVuetifyDynamic',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SampleVuetifyDynamic.vue')).default,
			},
			SelectControl: {
				_twor: true,
				source: 'waln',
				name: 'SelectControl',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/samples/SelectControl.vue')).default,
			},
		},
		sheets: {
			SheetCenter: {
				_twor: true,
				source: 'waln',
				name: 'SheetCenter',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/sheets/SheetCenter.vue')).default,
			},
			SheetFullscreen: {
				_twor: true,
				source: 'waln',
				name: 'SheetFullscreen',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/sheets/SheetFullscreen.vue')).default,
			},
			SheetSide: {
				_twor: true,
				source: 'waln',
				name: 'SheetSide',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/sheets/SheetSide.vue')).default,
			},
			SheetSwipe: {
				_twor: true,
				source: 'waln',
				name: 'SheetSwipe',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/sheets/SheetSwipe.vue')).default,
			},
			SheetTabs: {
				_twor: true,
				source: 'waln',
				name: 'SheetTabs',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/sheets/SheetTabs.vue')).default,
			},
		},
		test: {
			TestStore: {
				_twor: true,
				source: 'waln',
				name: 'TestStore',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/test/TestStore.vue')).default,
			},
		},
	},
	twor: {
		BlockMeta: {
			_twor: true,
			source: 'twor',
			name: 'BlockMeta',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/BlockMeta.vue')).default,
		},
		ComponentConstructor: {
			_twor: true,
			source: 'twor',
			name: 'ComponentConstructor',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ComponentConstructor.vue')).default,
		},
		ComponentConstructorContent: {
			_twor: true,
			source: 'twor',
			name: 'ComponentConstructorContent',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ComponentConstructorContent.vue')).default,
		},
		ConstructCollection: {
			_twor: true,
			source: 'twor',
			name: 'ConstructCollection',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructCollection.vue')).default,
		},
		ConstructComponent: {
			_twor: true,
			source: 'twor',
			name: 'ConstructComponent',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructComponent.vue')).default,
		},
		ConstructForm: {
			_twor: true,
			source: 'twor',
			name: 'ConstructForm',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructForm.vue')).default,
		},
		ConstructLayout: {
			_twor: true,
			source: 'twor',
			name: 'ConstructLayout',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructLayout.vue')).default,
		},
		ConstructMap: {
			_twor: true,
			source: 'twor',
			name: 'ConstructMap',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructMap.vue')).default,
		},
		ConstructTable: {
			_twor: true,
			source: 'twor',
			name: 'ConstructTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ConstructTable.vue')).default,
		},
		ScreenFlow: {
			_twor: true,
			source: 'twor',
			name: 'ScreenFlow',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/ScreenFlow.vue')).default,
		},
		mocks: {
			MockButton: {
				_twor: true,
				source: 'twor',
				name: 'MockButton',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockButton.vue')).default,
			},
			MockCalendar: {
				_twor: true,
				source: 'twor',
				name: 'MockCalendar',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockCalendar.vue')).default,
			},
			MockChat: {
				_twor: true,
				source: 'twor',
				name: 'MockChat',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockChat.vue')).default,
			},
			MockHeading: {
				_twor: true,
				source: 'twor',
				name: 'MockHeading',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockHeading.vue')).default,
			},
			MockImage: {
				_twor: true,
				source: 'twor',
				name: 'MockImage',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockImage.vue')).default,
			},
			MockInput: {
				_twor: true,
				source: 'twor',
				name: 'MockInput',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockInput.vue')).default,
			},
			MockList: {
				_twor: true,
				source: 'twor',
				name: 'MockList',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockList.vue')).default,
			},
			MockText: {
				_twor: true,
				source: 'twor',
				name: 'MockText',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockText.vue')).default,
			},
			MockTextarea: {
				_twor: true,
				source: 'twor',
				name: 'MockTextarea',
				import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/twor/src/components/mocks/MockTextarea.vue')).default,
			},
		},
	},
	threestyle: {
		LayoutEmpty: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutEmpty',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutEmpty.vue')).default,
		},
		LayoutRounded: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRounded',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRounded.vue')).default,
		},
		LayoutRoundedGradientBlue: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGradientBlue',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGradientBlue.vue')).default,
		},
		LayoutRoundedGradientBlueTexture: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGradientBlueTexture',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGradientBlueTexture.vue')).default,
		},
		LayoutRoundedGradientPink: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGradientPink',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGradientPink.vue')).default,
		},
		LayoutRoundedGradientViolet: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGradientViolet',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGradientViolet.vue')).default,
		},
		LayoutRoundedGray: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGray',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGray.vue')).default,
		},
		LayoutRoundedGrayDark: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedGrayDark',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedGrayDark.vue')).default,
		},
		LayoutRoundedOutlined: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutRoundedOutlined',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutRoundedOutlined.vue')).default,
		},
		LayoutShadowRounded: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutShadowRounded',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutShadowRounded.vue')).default,
		},
		LayoutShadowTopRightRounded: {
			_twor: true,
			source: 'threestyle',
			name: 'LayoutShadowTopRightRounded',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/threestyle/src/components/LayoutShadowTopRightRounded.vue')).default,
		},
	},
	threek: {
		TabsSwipe: {
			_twor: true,
			source: 'threek',
			name: 'TabsSwipe',
			import: async () => (await import('/home/ubuntu/creldone/libs/threek/src/components/TabsSwipe.vue')).default,
		},
		apps: {
			App: {
				_twor: true,
				source: 'threek',
				name: 'App',
				import: async () => (await import('/home/ubuntu/creldone/libs/threek/src/components/apps/App.vue')).default,
			},
		},
	},
	v: {
		VApp: {
			_twor: true,
			source: 'vuetify',
			name: 'VApp',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VApp/index.js')).default,
		},
		VAppBar: {
			_twor: true,
			source: 'vuetify',
			name: 'VAppBar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAppBar/index.js')).VAppBar,
		},
		VAppBarNavIcon: {
			_twor: true,
			source: 'vuetify',
			name: 'VAppBar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAppBar/index.js')).VAppBarNavIcon,
		},
		VAppBarTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VAppBar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAppBar/index.js')).VAppBarTitle,
		},
		VAlert: {
			_twor: true,
			source: 'vuetify',
			name: 'VAlert',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAlert/index.js')).default,
		},
		VAutocomplete: {
			_twor: true,
			source: 'vuetify',
			name: 'VAutocomplete',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAutocomplete/index.js')).default,
		},
		VAvatar: {
			_twor: true,
			source: 'vuetify',
			name: 'VAvatar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VAvatar/index.js')).default,
		},
		VBanner: {
			_twor: true,
			source: 'vuetify',
			name: 'VBanner',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBanner/index.js')).default,
		},
		VBadge: {
			_twor: true,
			source: 'vuetify',
			name: 'VBadge',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBadge/index.js')).default,
		},
		VBottomNavigation: {
			_twor: true,
			source: 'vuetify',
			name: 'VBottomNavigation',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBottomNavigation/index.js')).default,
		},
		VBottomSheet: {
			_twor: true,
			source: 'vuetify',
			name: 'VBottomSheet',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBottomSheet/index.js')).default,
		},
		VBreadcrumbs: {
			_twor: true,
			source: 'vuetify',
			name: 'VBreadcrumbs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBreadcrumbs/index.js')).VBreadcrumbs,
		},
		VBreadcrumbsItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VBreadcrumbs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBreadcrumbs/index.js')).VBreadcrumbsItem,
		},
		VBreadcrumbsDivider: {
			_twor: true,
			source: 'vuetify',
			name: 'VBreadcrumbs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBreadcrumbs/index.js')).VBreadcrumbsDivider,
		},
		VBtn: {
			_twor: true,
			source: 'vuetify',
			name: 'VBtn',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBtn/index.js')).default,
		},
		VBtnToggle: {
			_twor: true,
			source: 'vuetify',
			name: 'VBtnToggle',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBtnToggle/index.js')).default,
		},
		VCalendar: {
			_twor: true,
			source: 'vuetify',
			name: 'VCalendar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCalendar/index.js')).VCalendar,
		},
		VCalendarCategory: {
			_twor: true,
			source: 'vuetify',
			name: 'VCalendar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCalendar/index.js')).VCalendarCategory,
		},
		VCalendarDaily: {
			_twor: true,
			source: 'vuetify',
			name: 'VCalendar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCalendar/index.js')).VCalendarDaily,
		},
		VCalendarWeekly: {
			_twor: true,
			source: 'vuetify',
			name: 'VCalendar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCalendar/index.js')).VCalendarWeekly,
		},
		VCalendarMonthly: {
			_twor: true,
			source: 'vuetify',
			name: 'VCalendar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCalendar/index.js')).VCalendarMonthly,
		},
		VCard: {
			_twor: true,
			source: 'vuetify',
			name: 'VCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCard/index.js')).VCard,
		},
		VCardActions: {
			_twor: true,
			source: 'vuetify',
			name: 'VCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCard/index.js')).VCardActions,
		},
		VCardSubtitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCard/index.js')).VCardSubtitle,
		},
		VCardText: {
			_twor: true,
			source: 'vuetify',
			name: 'VCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCard/index.js')).VCardText,
		},
		VCardTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VCard',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCard/index.js')).VCardTitle,
		},
		VCarousel: {
			_twor: true,
			source: 'vuetify',
			name: 'VCarousel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCarousel/index.js')).VCarousel,
		},
		VCarouselItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VCarousel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCarousel/index.js')).VCarouselItem,
		},
		VCheckbox: {
			_twor: true,
			source: 'vuetify',
			name: 'VCheckbox',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCheckbox/index.js')).VCheckbox,
		},
		VSimpleCheckbox: {
			_twor: true,
			source: 'vuetify',
			name: 'VCheckbox',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCheckbox/index.js')).VSimpleCheckbox,
		},
		VChip: {
			_twor: true,
			source: 'vuetify',
			name: 'VChip',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VChip/index.js')).default,
		},
		VChipGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VChipGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VChipGroup/index.js')).default,
		},
		VContent: {
			_twor: true,
			source: 'vuetify',
			name: 'VContent',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VContent/index.js')).default,
		},
		VColorPicker: {
			_twor: true,
			source: 'vuetify',
			name: 'VColorPicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VColorPicker/index.js')).default,
		},
		VCombobox: {
			_twor: true,
			source: 'vuetify',
			name: 'VCombobox',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCombobox/index.js')).default,
		},
		VCounter: {
			_twor: true,
			source: 'vuetify',
			name: 'VCounter',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VCounter/index.js')).default,
		},
		VData: {
			_twor: true,
			source: 'vuetify',
			name: 'VData',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VData/index.js')).default,
		},
		VDataIterator: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataIterator',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataIterator/index.js')).VDataIterator,
		},
		VDataFooter: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataIterator',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataIterator/index.js')).VDataFooter,
		},
		VDataTable: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VDataTable,
		},
		VDataTableHeader: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VDataTableHeader,
		},
		VEditDialog: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VEditDialog,
		},
		VTableOverflow: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VTableOverflow,
		},
		VSimpleTable: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VSimpleTable,
		},
		VVirtualTable: {
			_twor: true,
			source: 'vuetify',
			name: 'VDataTable',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDataTable/index.js')).VVirtualTable,
		},
		VDatePicker: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePicker,
		},
		VDatePickerTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePickerTitle,
		},
		VDatePickerHeader: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePickerHeader,
		},
		VDatePickerDateTable: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePickerDateTable,
		},
		VDatePickerMonthTable: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePickerMonthTable,
		},
		VDatePickerYears: {
			_twor: true,
			source: 'vuetify',
			name: 'VDatePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDatePicker/index.js')).VDatePickerYears,
		},
		VDialog: {
			_twor: true,
			source: 'vuetify',
			name: 'VDialog',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDialog/index.js')).default,
		},
		VDivider: {
			_twor: true,
			source: 'vuetify',
			name: 'VDivider',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VDivider/index.js')).default,
		},
		VExpansionPanels: {
			_twor: true,
			source: 'vuetify',
			name: 'VExpansionPanel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VExpansionPanel/index.js')).VExpansionPanels,
		},
		VExpansionPanel: {
			_twor: true,
			source: 'vuetify',
			name: 'VExpansionPanel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VExpansionPanel/index.js')).VExpansionPanel,
		},
		VExpansionPanelHeader: {
			_twor: true,
			source: 'vuetify',
			name: 'VExpansionPanel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VExpansionPanel/index.js')).VExpansionPanelHeader,
		},
		VExpansionPanelContent: {
			_twor: true,
			source: 'vuetify',
			name: 'VExpansionPanel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VExpansionPanel/index.js')).VExpansionPanelContent,
		},
		VFileInput: {
			_twor: true,
			source: 'vuetify',
			name: 'VFileInput',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VFileInput/index.js')).default,
		},
		VFooter: {
			_twor: true,
			source: 'vuetify',
			name: 'VFooter',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VFooter/index.js')).default,
		},
		VContainer: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VContainer,
		},
		VCol: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VCol,
		},
		VRow: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VRow,
		},
		VSpacer: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VSpacer,
		},
		VLayout: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VLayout,
		},
		VFlex: {
			_twor: true,
			source: 'vuetify',
			name: 'VGrid',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VGrid/index.js')).VFlex,
		},
		VForm: {
			_twor: true,
			source: 'vuetify',
			name: 'VForm',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VForm/index.js')).default,
		},
		VHover: {
			_twor: true,
			source: 'vuetify',
			name: 'VHover',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VHover/index.js')).default,
		},
		VIcon: {
			_twor: true,
			source: 'vuetify',
			name: 'VIcon',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VIcon/index.js')).default,
		},
		VImg: {
			_twor: true,
			source: 'vuetify',
			name: 'VImg',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VImg/index.js')).default,
		},
		VInput: {
			_twor: true,
			source: 'vuetify',
			name: 'VInput',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VInput/index.js')).default,
		},
		VItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VItemGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VItemGroup/index.js')).VItem,
		},
		VItemGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VItemGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VItemGroup/index.js')).VItemGroup,
		},
		VLabel: {
			_twor: true,
			source: 'vuetify',
			name: 'VLabel',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VLabel/index.js')).default,
		},
		VLazy: {
			_twor: true,
			source: 'vuetify',
			name: 'VLazy',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VLazy/index.js')).default,
		},
		VMain: {
			_twor: true,
			source: 'vuetify',
			name: 'VMain',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VMain/index.js')).default,
		},
		VList: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VList,
		},
		VListGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListGroup,
		},
		VListItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItem,
		},
		VListItemAction: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemAction,
		},
		VListItemActionText: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemActionText,
		},
		VListItemAvatar: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemAvatar,
		},
		VListItemContent: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemContent,
		},
		VListItemGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemGroup,
		},
		VListItemIcon: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemIcon,
		},
		VListItemSubtitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemSubtitle,
		},
		VListItemTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VList',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VList/index.js')).VListItemTitle,
		},
		VMenu: {
			_twor: true,
			source: 'vuetify',
			name: 'VMenu',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VMenu/index.js')).default,
		},
		VMessages: {
			_twor: true,
			source: 'vuetify',
			name: 'VMessages',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VMessages/index.js')).default,
		},
		VNavigationDrawer: {
			_twor: true,
			source: 'vuetify',
			name: 'VNavigationDrawer',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VNavigationDrawer/index.js')).default,
		},
		VOtpInput: {
			_twor: true,
			source: 'vuetify',
			name: 'VOtpInput',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VOtpInput/index.js')).default,
		},
		VOverflowBtn: {
			_twor: true,
			source: 'vuetify',
			name: 'VOverflowBtn',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VOverflowBtn/index.js')).default,
		},
		VParallax: {
			_twor: true,
			source: 'vuetify',
			name: 'VParallax',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VParallax/index.js')).default,
		},
		VPagination: {
			_twor: true,
			source: 'vuetify',
			name: 'VPagination',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VPagination/index.js')).default,
		},
		VPicker: {
			_twor: true,
			source: 'vuetify',
			name: 'VPicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VPicker/index.js')).default,
		},
		VProgressCircular: {
			_twor: true,
			source: 'vuetify',
			name: 'VProgressCircular',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VProgressCircular/index.js')).default,
		},
		VProgressLinear: {
			_twor: true,
			source: 'vuetify',
			name: 'VProgressLinear',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VProgressLinear/index.js')).default,
		},
		VRangeSlider: {
			_twor: true,
			source: 'vuetify',
			name: 'VRangeSlider',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VRangeSlider/index.js')).default,
		},
		VRadioGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VRadioGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VRadioGroup/index.js')).VRadioGroup,
		},
		VRadio: {
			_twor: true,
			source: 'vuetify',
			name: 'VRadioGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VRadioGroup/index.js')).VRadio,
		},
		VRating: {
			_twor: true,
			source: 'vuetify',
			name: 'VRating',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VRating/index.js')).default,
		},
		VResponsive: {
			_twor: true,
			source: 'vuetify',
			name: 'VResponsive',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VResponsive/index.js')).default,
		},
		VSelect: {
			_twor: true,
			source: 'vuetify',
			name: 'VSelect',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSelect/index.js')).default,
		},
		VSkeletonLoader: {
			_twor: true,
			source: 'vuetify',
			name: 'VSkeletonLoader',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSkeletonLoader/index.js')).default,
		},
		VSlider: {
			_twor: true,
			source: 'vuetify',
			name: 'VSlider',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSlider/index.js')).default,
		},
		VSnackbar: {
			_twor: true,
			source: 'vuetify',
			name: 'VSnackbar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSnackbar/index.js')).default,
		},
		VSlideGroup: {
			_twor: true,
			source: 'vuetify',
			name: 'VSlideGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSlideGroup/index.js')).VSlideGroup,
		},
		VSlideItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VSlideGroup',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSlideGroup/index.js')).VSlideItem,
		},
		VSparkline: {
			_twor: true,
			source: 'vuetify',
			name: 'VSparkline',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSparkline/index.js')).default,
		},
		VStepper: {
			_twor: true,
			source: 'vuetify',
			name: 'VStepper',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VStepper/index.js')).VStepper,
		},
		VStepperContent: {
			_twor: true,
			source: 'vuetify',
			name: 'VStepper',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VStepper/index.js')).VStepperContent,
		},
		VStepperStep: {
			_twor: true,
			source: 'vuetify',
			name: 'VStepper',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VStepper/index.js')).VStepperStep,
		},
		VStepperHeader: {
			_twor: true,
			source: 'vuetify',
			name: 'VStepper',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VStepper/index.js')).VStepperHeader,
		},
		VStepperItems: {
			_twor: true,
			source: 'vuetify',
			name: 'VStepper',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VStepper/index.js')).VStepperItems,
		},
		VSpeedDial: {
			_twor: true,
			source: 'vuetify',
			name: 'VSpeedDial',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSpeedDial/index.js')).default,
		},
		VSubheader: {
			_twor: true,
			source: 'vuetify',
			name: 'VSubheader',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSubheader/index.js')).default,
		},
		VSwitch: {
			_twor: true,
			source: 'vuetify',
			name: 'VSwitch',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSwitch/index.js')).default,
		},
		VSystemBar: {
			_twor: true,
			source: 'vuetify',
			name: 'VSystemBar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSystemBar/index.js')).default,
		},
		VTabs: {
			_twor: true,
			source: 'vuetify',
			name: 'VTabs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTabs/index.js')).VTabs,
		},
		VTab: {
			_twor: true,
			source: 'vuetify',
			name: 'VTabs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTabs/index.js')).VTab,
		},
		VTabsItems: {
			_twor: true,
			source: 'vuetify',
			name: 'VTabs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTabs/index.js')).VTabsItems,
		},
		VTabItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VTabs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTabs/index.js')).VTabItem,
		},
		VTabsSlider: {
			_twor: true,
			source: 'vuetify',
			name: 'VTabs',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTabs/index.js')).VTabsSlider,
		},
		VTextarea: {
			_twor: true,
			source: 'vuetify',
			name: 'VTextarea',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTextarea/index.js')).default,
		},
		VTextField: {
			_twor: true,
			source: 'vuetify',
			name: 'VTextField',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTextField/index.js')).default,
		},
		VThemeProvider: {
			_twor: true,
			source: 'vuetify',
			name: 'VThemeProvider',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VThemeProvider/index.js')).default,
		},
		VTimePicker: {
			_twor: true,
			source: 'vuetify',
			name: 'VTimePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTimePicker/index.js')).VTimePicker,
		},
		VTimePickerClock: {
			_twor: true,
			source: 'vuetify',
			name: 'VTimePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTimePicker/index.js')).VTimePickerClock,
		},
		VTimePickerTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VTimePicker',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTimePicker/index.js')).VTimePickerTitle,
		},
		VTimeline: {
			_twor: true,
			source: 'vuetify',
			name: 'VTimeline',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTimeline/index.js')).VTimeline,
		},
		VTimelineItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VTimeline',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTimeline/index.js')).VTimelineItem,
		},
		VToolbar: {
			_twor: true,
			source: 'vuetify',
			name: 'VToolbar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VToolbar/index.js')).VToolbar,
		},
		VToolbarItems: {
			_twor: true,
			source: 'vuetify',
			name: 'VToolbar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VToolbar/index.js')).VToolbarItems,
		},
		VToolbarTitle: {
			_twor: true,
			source: 'vuetify',
			name: 'VToolbar',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VToolbar/index.js')).VToolbarTitle,
		},
		VTooltip: {
			_twor: true,
			source: 'vuetify',
			name: 'VTooltip',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTooltip/index.js')).default,
		},
		VTreeview: {
			_twor: true,
			source: 'vuetify',
			name: 'VTreeview',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTreeview/index.js')).VTreeview,
		},
		VTreeviewNode: {
			_twor: true,
			source: 'vuetify',
			name: 'VTreeview',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTreeview/index.js')).VTreeviewNode,
		},
		VVirtualScroll: {
			_twor: true,
			source: 'vuetify',
			name: 'VVirtualScroll',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VVirtualScroll/index.js')).default,
		},
		VWindow: {
			_twor: true,
			source: 'vuetify',
			name: 'VWindow',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VWindow/index.js')).VWindow,
		},
		VWindowItem: {
			_twor: true,
			source: 'vuetify',
			name: 'VWindow',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VWindow/index.js')).VWindowItem,
		},
		VCarouselTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VCarouselTransition,
		},
		VCarouselReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VCarouselReverseTransition,
		},
		VDialogTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VDialogTransition,
		},
		VDialogBottomTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VDialogBottomTransition,
		},
		VDialogTopTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VDialogTopTransition,
		},
		VFabTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VFabTransition,
		},
		VFadeTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VFadeTransition,
		},
		VMenuTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VMenuTransition,
		},
		VScaleTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VScaleTransition,
		},
		VScrollXTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VScrollXTransition,
		},
		VScrollXReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VScrollXReverseTransition,
		},
		VScrollYTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VScrollYTransition,
		},
		VScrollYReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VScrollYReverseTransition,
		},
		VSlideXTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VSlideXTransition,
		},
		VSlideXReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VSlideXReverseTransition,
		},
		VSlideYTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VSlideYTransition,
		},
		VSlideYReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VSlideYReverseTransition,
		},
		VTabReverseTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VTabReverseTransition,
		},
		VTabTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VTabTransition,
		},
		VExpandTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VExpandTransition,
		},
		VExpandXTransition: {
			_twor: true,
			source: 'vuetify',
			name: 'transitions',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/transitions/index.js')).VExpandXTransition,
		},
		VOverlay: {
			_twor: true,
			source: 'vuetify',
			name: 'VOverlay',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VOverlay/index.js')).default,
		},
		VSheet: {
			_twor: true,
			source: 'vuetify',
			name: 'VSheet',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VSheet/index.js')).default,
		},
	},
	div: {
		_twor: true,
		source: 'waln',
		name: 'BlockEmpty',
		import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/src/libs/waln/components/BlockEmpty.vue')).default,
	},
	btn: {
		_twor: true,
		source: 'vuetify',
		name: 'VBtn',
		import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBtn/index.js')).default,
	},
	input: {
		_twor: true,
		source: 'vuetify',
		name: 'VTextField',
		import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VTextField/index.js')).default,
	},
	img: {
		_twor: true,
		source: 'vuetify',
		name: 'VImg',
		import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VImg/index.js')).default,
	},
	d: {
		btnPrimary: {
			_twor: true,
			source: 'vuetify',
			name: 'VBtn',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBtn/index.js')).default,
			localName: 'btnPrimary',
		},
		btnPrimaryLarge: {
			_twor: true,
			source: 'vuetify',
			name: 'VBtn',
			import: async () => (await import('/home/ubuntu/creldone/apps/demo-food/node_modules/vuetify/lib/components/VBtn/index.js')).default,
			localName: 'btnPrimaryLarge',
		},
	},
}